@import "../node_modules/materialize-css/dist/css/materialize.min.css";

#app {
  margin-top: 3rem;
}

#progressComponent {
  margin: 3rem 0;
}

.github-logo {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 64px;
}
